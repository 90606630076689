<template>
  <div>
    <v-card id="log-list">
      <!-- top -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

        <div class="d-flex align-center pb-5">
          <!-- filter branch -->
          <v-combobox
            v-model="branchFilters"
            :items="branches"
            item-text="name"
            single-line
            multiple
            outlined
            dense
            hide-details
            clearable
            :placeholder="t('Select Branch')"
            class="log-list-branch"
          ></v-combobox>
        </div>
      </v-card-text>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <!-- Date range -->
          <v-menu
            v-model="isDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :append-icon="icons.mdiCalendar"
                :value="dateRangeText"
                readonly
                dense
                outlined
                hide-details
                class="me-4"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dateRange"
              color="primary"
              :first-day-of-week="1"
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                block
                @click="dateRangeChange()"
              >
                {{ t('Set Dates') }}
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex flex-wrap align-center pb-5">
          <div class="d-flex align-center">
            <!-- filter accounts -->
            <v-combobox
              v-model="productFilters"
              :items="insuranceProducts"
              item-text="name"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Product Types')"
              :class="{ 'log-list-product me-3': $vuetify.breakpoint.smAndUp, 'pb-5': $vuetify.breakpoint.xsOnly }"
            >
              <template v-slot:selection="{item}">
                {{ item.name.split(' ')[0] }}
              </template>
            </v-combobox>
          </div>

          <div class="d-flex align-center">
            <!-- filter accounts -->
            <v-combobox
              v-model="accountFilters"
              :items="accounts"
              item-text="nickname"
              single-line
              multiple
              outlined
              dense
              hide-details
              clearable
              :placeholder="t('Select Accounts')"
              :class="{ 'log-list-account': $vuetify.breakpoint.smAndUp }"
            >
              <template v-slot:selection="{item}">
                {{ item.initials }}
              </template>
            </v-combobox>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <app-card-actions
        action-refresh
        @refresh="fetchLogs"
      >
        <template slot="title">
          <div class="d-flex align-center">
            <v-select
              v-model="tablePagination.per"
              :items="listLengthOptions"
              single-line
              outlined
              dense
              hide-details
              class="log-list-row-selection"
              @input="listLengthChange()"
            ></v-select>
            <div
              class="pl-3"
              style="font-size: 14px"
            >
              {{ t('Rows') }}
            </div>
          </div>

          <v-spacer></v-spacer>

          <div class="d-flex align-center justify-end">
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </div>
        </template>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="logs"
          :options.sync="options"
          :items-per-page="100"
          :loading="loading"
          hide-default-footer
          :disable-sort="true"
        >
          <!-- actions -->
          <template #[`item.actions`]="{item}">
            <div class="d-flex align-center justify-center">
              <!-- view  -->
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :key="item.id"
                    icon
                    small
                    v-bind="attrs"
                    :to="{ name: `apps-${item.record.type}-preview`, params: { id: item.record.id } }"
                    v-on="on"
                  >
                    <v-icon size="18">
                      {{ icons.mdiEyeOutline }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ t('View Log') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>

        <v-card-text class="d-flex justify-space-between mt-9 pt-5">
          <div class="d-flex align-center">
            <v-select
              v-model="tablePagination.per"
              :items="listLengthOptions"
              single-line
              outlined
              dense
              hide-details
              class="log-list-row-selection"
              @input="listLengthChange()"
            ></v-select>
            <div class="pl-3">
              {{ t('Rows') }}
            </div>
          </div>

          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>

          <div
            class="d-flex align-center justify-end"
            :class="{ 'pr-8': $vuetify.breakpoint.smAndUp }"
          >
            <v-pagination
              v-model="tablePagination.current_page"
              total-visible="6"
              :length="tablePagination.total_page"
              @input="pageChange()"
            ></v-pagination>
          </div>
        </v-card-text>
      </app-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mdiEyeOutline,
  mdiCalendar,
} from '@mdi/js'
import {
  ref,
  inject,
  computed,
  watch,
} from '@vue/composition-api'
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'

export default {
  components: {
    AppCardActions,
  },
  setup() {
    // Initializers
    const store = inject('store')
    const snackbarService = inject('snackbarService')
    const t = inject('t')

    // Table Handlers
    const tableColumns = computed(() => (
      [
        { text: t('Datetime'), value: 'created_at' },
        { text: t('Description'), value: 'description' },
        { align: 'center', value: 'actions', sortable: false },
      ]
    ))

    // Properties
    const [dayNow, monthNow, yearNow] = new Date().toLocaleDateString('en-SG').split('/')
    const logs = ref([])
    const tablePagination = ref({
      current_page: 1,
      per: 20,
      total_count: 0,
      total_page: 0,
    })
    const options = ref({
      sortBy: ['id'],
      sortDesc: [false],
    })
    const loading = ref(false)
    const branchFilters = ref([])
    const accountFilters = ref([])
    const productFilters = ref([])
    const dateRange = ref([`${yearNow}-${monthNow}-${dayNow}`])
    const listLengthOptions = ref([20, 50, 100])
    const isDateMenuOpen = ref(false)

    // Utils
    const formatDate = date => {
      if (!date) return null
      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    }

    // Computed
    const branches = computed(() => store.state.branches)
    const accounts = computed(() => store.state.accounts)
    const insuranceProducts = computed(() => store.state.insuranceProducts)
    const dateRangeText = computed(() => {
      if (Date.parse(dateRange.value[0]) > Date.parse(dateRange.value[1])) {
        const [start, end] = dateRange.value
        dateRange.value[0] = end
        dateRange.value[1] = start
      }

      const startDateText = formatDate(dateRange.value[0])
      const showEndDate = dateRange.value[0] !== dateRange.value[1] && formatDate(dateRange.value[1]) !== null
      const endDateText = showEndDate ? ` - ${formatDate(dateRange.value[1])}` : ''

      return `${startDateText}${endDateText}`
    })

    // Methods
    const isLoading = () => {
      loading.value = true
      snackbarService.info('Loading your list. Please hold...')
    }
    const doneLoading = () => {
      loading.value = false
      snackbarService.success('Done loading your list!')
    }
    const fetchLogs = (hideOverlay = null) => {
      store
        .dispatch('fetchLogs', {
          page: hideOverlay ? 1 : tablePagination.value.current_page,
          per: tablePagination.value.per,
          branch_ids: branchFilters.value.map(branch => branch.id),
          user_ids: accountFilters.value.map(account => account.id),
          product_ids: productFilters.value.map(product => product.id),
          start_date: dateRange.value[0],
          end_date: dateRange.value[1] ? dateRange.value[1] : dateRange.value[0],
        })
        .then(response => {
          const { data, pagination } = response.data
          logs.value = data
          tablePagination.value = pagination
          if (hideOverlay) hideOverlay()
          doneLoading()
        })
        .catch(error => {
          loading.value = false
          snackbarService.error(error.response.data.message || 'Something went wrong while fetching logs. Please refresh!')
        })
    }
    const pageChange = () => {
      isLoading()
      fetchLogs()
    }
    const listLengthChange = () => {
      tablePagination.value.current_page = 1
      isLoading()
      fetchLogs()
    }
    const dateRangeChange = () => {
      isDateMenuOpen.value = false
      tablePagination.value.current_page = 1
      isLoading()
      fetchLogs()
    }

    // Watch
    watch([branchFilters, accountFilters, productFilters, options], () => {
      isLoading()
      fetchLogs()
    })

    return {
      t,
      loading,
      logs,
      branches,
      accounts,
      insuranceProducts,
      tablePagination,
      tableColumns,
      isDateMenuOpen,
      listLengthOptions,
      options,
      branchFilters,
      accountFilters,
      productFilters,
      dateRange,
      dateRangeText,
      fetchLogs,
      pageChange,
      listLengthChange,
      dateRangeChange,
      formatDate,
      icons: {
        mdiEyeOutline,
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
  #log-list {
    .log-list-search {
      width: 29rem;
    }

    .log-list-status {
      max-width: 11.3rem;
    }

    .log-list-branch {
      max-width: 33rem;
    }

    .log-list-account {
      max-width: 20rem;
    }

    .log-list-product {
      max-width: 28rem;
    }

    .log-list-row-selection {
      max-width: 5.3rem;
    }

    .log-net-payment {
      min-width: 10rem;
    }
  }

  .log-button {
    width: 251px;
  }
</style>
