var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"id":"log-list"}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-combobox',{staticClass:"log-list-branch",attrs:{"items":_vm.branches,"item-text":"name","single-line":"","multiple":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.t('Select Branch')},model:{value:(_vm.branchFilters),callback:function ($$v) {_vm.branchFilters=$$v},expression:"branchFilters"}})],1)],1),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"me-4",attrs:{"append-icon":_vm.icons.mdiCalendar,"value":_vm.dateRangeText,"readonly":"","dense":"","outlined":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isDateMenuOpen),callback:function ($$v) {_vm.isDateMenuOpen=$$v},expression:"isDateMenuOpen"}},[_c('v-date-picker',{attrs:{"color":"primary","first-day-of-week":1,"range":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.dateRangeChange()}}},[_vm._v(" "+_vm._s(_vm.t('Set Dates'))+" ")])],1)],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-wrap align-center pb-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-combobox',{class:{ 'log-list-product me-3': _vm.$vuetify.breakpoint.smAndUp, 'pb-5': _vm.$vuetify.breakpoint.xsOnly },attrs:{"items":_vm.insuranceProducts,"item-text":"name","single-line":"","multiple":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.t('Select Product Types')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name.split(' ')[0])+" ")]}}]),model:{value:(_vm.productFilters),callback:function ($$v) {_vm.productFilters=$$v},expression:"productFilters"}})],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-combobox',{class:{ 'log-list-account': _vm.$vuetify.breakpoint.smAndUp },attrs:{"items":_vm.accounts,"item-text":"nickname","single-line":"","multiple":"","outlined":"","dense":"","hide-details":"","clearable":"","placeholder":_vm.t('Select Accounts')},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.initials)+" ")]}}]),model:{value:(_vm.accountFilters),callback:function ($$v) {_vm.accountFilters=$$v},expression:"accountFilters"}})],1)])],1),_c('v-divider'),_c('app-card-actions',{attrs:{"action-refresh":""},on:{"refresh":_vm.fetchLogs}},[_c('template',{slot:"title"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{staticClass:"log-list-row-selection",attrs:{"items":_vm.listLengthOptions,"single-line":"","outlined":"","dense":"","hide-details":""},on:{"input":function($event){return _vm.listLengthChange()}},model:{value:(_vm.tablePagination.per),callback:function ($$v) {_vm.$set(_vm.tablePagination, "per", $$v)},expression:"tablePagination.per"}}),_c('div',{staticClass:"pl-3",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.t('Rows'))+" ")])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.tablePagination.total_page},on:{"input":function($event){return _vm.pageChange()}},model:{value:(_vm.tablePagination.current_page),callback:function ($$v) {_vm.$set(_vm.tablePagination, "current_page", $$v)},expression:"tablePagination.current_page"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.logs,"options":_vm.options,"items-per-page":100,"loading":_vm.loading,"hide-default-footer":"","disable-sort":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:item.id,attrs:{"icon":"","small":"","to":{ name: ("apps-" + (item.record.type) + "-preview"), params: { id: item.record.id } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.t('View Log')))])])],1)]}}],null,true)}),_c('v-card-text',{staticClass:"d-flex justify-space-between mt-9 pt-5"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-select',{staticClass:"log-list-row-selection",attrs:{"items":_vm.listLengthOptions,"single-line":"","outlined":"","dense":"","hide-details":""},on:{"input":function($event){return _vm.listLengthChange()}},model:{value:(_vm.tablePagination.per),callback:function ($$v) {_vm.$set(_vm.tablePagination, "per", $$v)},expression:"tablePagination.per"}}),_c('div',{staticClass:"pl-3"},[_vm._v(" "+_vm._s(_vm.t('Rows'))+" ")])],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-spacer'):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-end",class:{ 'pr-8': _vm.$vuetify.breakpoint.smAndUp }},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.tablePagination.total_page},on:{"input":function($event){return _vm.pageChange()}},model:{value:(_vm.tablePagination.current_page),callback:function ($$v) {_vm.$set(_vm.tablePagination, "current_page", $$v)},expression:"tablePagination.current_page"}})],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }